/* eslint react/no-unused-prop-types: off */

import React, { FC } from 'react';
import classNames from 'classnames';
import Button from '../button';

import { ComponentSize, DropdownItem } from '../types';

import styles from '../forms.module.scss';

type Props = {
  search: string;
  highlighted: number;
  value?: any;
  options: Array<DropdownItem>;
  optionsPosition: 'left' | 'right';
  maxHeight: number;
  size?: ComponentSize;
  onHighlight: (n: number) => void;
  onSelect: (item: DropdownItem) => void;
};

const OptionsAlternative: FC<Props> = (props) => (
  <div className={styles.list_options__overlay}>
    {props.options.map((item, i) => (
      <Button
        variant="primary"
        key={i}
        isActive={item?.isActive || props.value === item.value}
        text={(item?.label || item?.value) as string}
        className={classNames((item?.class || '').split(' ').map((cl) => styles[cl]))}
        onClick={() => props.onSelect(item)}
      />
    ))}
  </div>
);

OptionsAlternative.defaultProps = {
  value: undefined,
  size: 'default'
};

export default OptionsAlternative;
