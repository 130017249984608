import React, {
  FC,
  forwardRef,
  CSSProperties,
  useState,
  useImperativeHandle,
  useRef,
  useEffect
} from 'react';
import classNames from 'classnames';
import useOnclickOutside from 'react-cool-onclickoutside';
import AutosizeInput from 'react-input-autosize';

import useHasMounted from 'hooks/use-has-mounted';
import { Translation } from 'types/engine/translation.type';
import { prepareToken } from 'src/utils/string';
import OverlayWrapper from 'components/common/overlay-wrapper';
import CarIcon from 'components/common/car-icon';
import { isMandatory, validateValue } from 'src/validators';
import useNonInitialEffect from 'hooks/use-non-initial-effect';
import { Validator, ValidatorResponse } from 'src/validators/types';
import { SlideUp } from 'components/common/animations';
import useTranslation from 'providers/translations/use-translations';
import Button from '../button';
import InputLabel from '../input-label';
import { ComponentSize, DropdownItem } from '../types';
import CurrentValue from './current-value';

import styles from '../forms.module.scss';
import OptionsList from './options-list';
import OptionsAlternative from './options-alternative';

type Props = {
  ref?: any;
  id?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  value?: DropdownItem;
  options?: Array<DropdownItem>;
  noEmptyOption?: boolean;
  searchEnabled?: boolean;
  onChange?: (item: DropdownItem) => void;
  onHighlightChange?: (item: DropdownItem) => void;
  validators?: Array<Validator>;
  errors?: Array<Translation>;
  alternativeList?: boolean;
  scrollMobileTop?: boolean;
  alternativeValign?: 'top' | 'center' | 'bottom';
  icon?: string;
  showExpandIcon?: boolean;

  // Appearance
  className?: string;
  classNames?: Array<string>;
  classNamesContent?: Array<string>;
  classNamesValueContainer?: Array<string>;
  style?: CSSProperties;
  size?: ComponentSize;
  hideAbsolutePlaceholder?: boolean;
  horizontal?: boolean;
  noBorder?: boolean;
  maxHeight?: number;
  optionsPosition?: 'left' | 'right';
  insideLabel?: boolean;
  disabled?: boolean;
};

const Dropdown: FC<Props> = forwardRef(function DropdownComponent(props, ref) {
  const containerEl = useRef(null);
  const searchRef = useRef(null);
  const hasMounted = useHasMounted();
  const { t } = useTranslation();
  const CustomOptions = props.alternativeList ? OptionsAlternative : OptionsList;

  const emptyOption: DropdownItem = {
    value: null,
    label: props.placeholder ? props.placeholder : '---'
  };

  const prepareOptions = (): Array<DropdownItem> =>
    (props.noEmptyOption ? [] : [emptyOption]).concat(props.options);

  const [id] = useState(props.id ? props.id : `input_${prepareToken()}`);
  const [value, setValue] = useState(props.value);
  const [options, setOptions] = useState<Array<DropdownItem>>(prepareOptions);
  const [search, setSearch] = useState('');
  const [highlighted, setHighlighted] = useState(-1);
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const [errors, setErrors] = useState<Array<Translation>>([]);
  const [expanded, setExpanded] = useState(false);

  const allErrors = errors.concat(props.errors || []);

  /* -------------------------------------------------------------------------------- HELPERS --- */

  const changeHighlighted = (i: number) => {
    setHighlighted(i);
    if (!props.onHighlightChange) return;
    props.onHighlightChange(options[i]);
  };

  const getContainerClasses = (): string => {
    const classes = ['input', props.className, styles.fieldContainer];
    if (props.classNames) classes.push(...props.classNames);
    if (focus) classes.push(styles['fieldContainer--focus']);
    if (props.horizontal) classes.push(styles['fieldContainer--horizontal']);
    if (props.size !== 'default') classes.push(styles[`fieldContainer--${props.size}`]);
    return classes.join(' ');
  };

  const getValueContainerClasses = (): string => {
    const classes = [
      styles.dropdownValue__container,
      props.disabled ? styles.dropdownValue__dropdownDisabled : undefined
    ];
    if (props.classNamesValueContainer) classes.push(...props.classNamesValueContainer);
    if (props.noBorder) classes.push(styles['dropdownValue__container--noBorder']);
    if (props.size !== 'default') classes.push(styles[`dropdownValue__container--${props.size}`]);

    if ((hover || focus) && !props.disabled) {
      classes.push(styles['dropdownValue__container--hover']);
    }

    if (allErrors.length > 0) {
      classes.push(styles['dropdownValue__container--error']);
    }
    return classes.join(' ');
  };

  const getContentClasses = (): string => {
    const classes = [styles.dropdownValue__content];
    if (props.classNamesContent) classes.push(...props.classNamesContent);

    return classes.join(' ');
  };

  /* ------------------------------------------------------------------------ EXPOSED METHODS --- */

  const runValidation = (): ValidatorResponse => {
    if (Array.isArray(props.errors) && props.errors.length > 0) {
      return { isValid: false, messages: props.errors, name: props.name };
    }
    if (props.validators === undefined) return { isValid: true };
    const { isValid, messages } = validateValue(value ? value.value : '', props.validators);
    setErrors(messages);
    return { isValid, messages, name: props.name };
  };

  useImperativeHandle(ref, () => ({
    validate(): ValidatorResponse {
      return runValidation();
    },
    getValue(): any {
      return value;
    }
  }));

  /* ------------------------------------------------------------------------------ INTERFACE --- */

  // Expand options
  const expand = () => {
    setExpanded(true);
    setFocus(true);
    if (searchRef.current) {
      searchRef.current.focus();
    }
  };

  // Collapse options
  const collapse = () => {
    setExpanded(false);
    setSearch('');
    changeHighlighted(-1);
    // setOptions(props.options);
    setFocus(false);
    if (searchRef.current) {
      searchRef.current.blur();
    }
  };

  /* --------------------------------------------------------------------------------- SEARCH --- */

  const itemMeetsFilter = (item: DropdownItem, phrase: string): boolean => {
    const val = item.searchPhrase || item.label || item.value;
    return val.toLowerCase().startsWith(phrase.toLowerCase());
  };

  const setSearchPhrase = (phrase: string) => {
    if (!props.searchEnabled) return;
    setSearch(phrase);
    changeHighlighted(-1);

    setOptions(
      phrase === ''
        ? props.options
        : props.options.filter((option) => itemMeetsFilter(option, phrase))
    );
  };

  /* -------------------------------------------------------------------------- HANDLE EVENTS --- */

  // Toggle expanded
  const toggleExpanded = () => {
    if (!props.disabled) {
      if (expanded) {
        collapse();
      } else {
        expand();
      }
    }
  };

  // Select item
  const selectOption = (item: DropdownItem) => {
    if (!item) return;
    collapse();
    setValue(item);
    props.onChange(item);
  };

  // Keyboard navigation
  const handleKeyPress = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        changeHighlighted(highlighted < options.length - 1 ? highlighted + 1 : 0);
        break;

      case 'ArrowUp':
        e.preventDefault();
        changeHighlighted(highlighted > 0 ? highlighted - 1 : options.length - 1);
        break;

      case 'Enter':
        e.preventDefault();
        selectOption(options[highlighted]);
        break;

      case 'Escape':
        collapse();
        break;

      default:
    }
  };

  // Close and reset state on click outside
  useOnclickOutside(
    () => {
      if (expanded) collapse();
    },
    { refs: [containerEl] }
  );

  // Watch for value and options changes
  useEffect(() => {
    if (value !== props.value) {
      setValue(props.value);
    }
    if (props.options !== options) {
      setOptions(prepareOptions);
    }
  }, [props.value, props.options]);

  // Watch arrow keys for disabled search
  useEffect(() => {
    if (!expanded || props.searchEnabled) return () => null;
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [highlighted, expanded, options]);

  useNonInitialEffect(() => {
    runValidation();
  }, [value]);

  /* ------------------------------------------------------------------------ RENDER TEMPLATE --- */

  if (!props.id && !hasMounted) return null;

  const showAbsoluteLabel =
    value &&
    (props.options || []).findIndex((el) => el.value === value) !== -1 &&
    props.placeholder &&
    !props.label &&
    !expanded;

  return (
    <div ref={containerEl} className={getContainerClasses()}>
      {!props.insideLabel && (
        <InputLabel
          label={props.label}
          id={id}
          errors={errors}
          required={isMandatory(props.validators)}
        />
      )}
      <div
        className={getValueContainerClasses()}
        style={{
          outlineStyle: expanded ? 'auto' : 'none',
          zIndex: (hover || focus) && !props.disabled ? 10 : undefined,
          ...props.style
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={toggleExpanded}
        onKeyPress={() => null}
        role="button"
        tabIndex={0}
      >
        {props.insideLabel && (
          <InputLabel
            label={props.label}
            id={id}
            errors={errors}
            required={props.validators.findIndex((item) => item.value === 'mandatory') !== -1}
            className={styles.insideLabel}
          />
        )}

        {showAbsoluteLabel && (
          <span className={styles.dropdownValue__absolutePlaceholder}>{props.placeholder}</span>
        )}
        <div className={getContentClasses()} style={{}}>
          {props.icon && (
            <CarIcon
              className={classNames(styles.dropdownValue__icon, {
                [styles['dropdownValue__icon--large']]: props.size === 'large'
              })}
              icon={props.icon}
              size={props.size === 'large' ? '28px' : '14px'}
            />
          )}
          {props.searchEnabled && (
            <AutosizeInput
              ref={searchRef}
              inputClassName={styles.dropdownValue__searchInput}
              name={id}
              value={search}
              onChange={(e) => {
                setSearchPhrase(e.target.value);
              }}
              onKeyDown={handleKeyPress}
            />
          )}
          {search === '' && (
            <CurrentValue
              value={value}
              allowedValues={(props.options || []).map((item) => item.value)}
              placeholder={props.placeholder}
              shift={showAbsoluteLabel}
            />
          )}
        </div>
        <Button
          disabled={props.disabled}
          className={classNames(
            styles.dropdownValue__arrow,
            props.size !== 'default' ? styles[`dropdownValue__arrow--${props.size}`] : undefined
          )}
          variant="text"
          icon={props.showExpandIcon && (expanded ? 'chevron-up' : 'chevron-down')}
          onClick={toggleExpanded}
        />

        <SlideUp open={allErrors.length > 0 && hover}>
          <ul className={classNames(styles.fieldErrors, 'fieldErrors')}>
            {allErrors.map((message, i) => (
              <li key={i}>{t(message.key, message.data)}</li>
            ))}
          </ul>
        </SlideUp>
      </div>

      <SlideUp open={expanded}>
        <OverlayWrapper
          open={expanded}
          inUse={props.alternativeList}
          valign={props.alternativeValign}
          scrollMobileTop={props.scrollMobileTop}
          closeBtn
          onClose={collapse}
          title={props.label}
        >
          <CustomOptions
            search={search}
            highlighted={highlighted}
            value={value ? value.value : null}
            options={options}
            size={props.size}
            optionsPosition={props.optionsPosition}
            maxHeight={props.maxHeight}
            onHighlight={changeHighlighted}
            onSelect={selectOption}
          />
        </OverlayWrapper>
      </SlideUp>
    </div>
  );
});

Dropdown.defaultProps = {
  options: [],
  noEmptyOption: false,
  searchEnabled: false,
  onChange: () => null,
  onHighlightChange: () => null,
  validators: [],
  hideAbsolutePlaceholder: false,
  horizontal: false,
  noBorder: false,
  maxHeight: 300,
  optionsPosition: 'left',
  insideLabel: false,
  alternativeValign: 'center',
  showExpandIcon: true,
  disabled: false,
  errors: [],
  scrollMobileTop: false
};

export default Dropdown;
