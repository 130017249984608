import useTranslation from 'providers/translations/use-translations';
import React, { FC, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import styles from '../forms.module.scss';
import { ComponentSize, DropdownItem } from '../types';
import Option from './option';

type Props = {
  search: string;
  highlighted: number;
  value?: any;
  options: Array<DropdownItem>;
  optionsPosition: 'left' | 'right';
  maxHeight: number;
  size?: ComponentSize;
  onHighlight: (n: number) => void;
  onSelect: (item: DropdownItem) => void;
};

const OptionsList: FC<Props> = ({
  search,
  highlighted,
  value = undefined,
  options,
  optionsPosition,
  maxHeight,
  size = 'default',
  onHighlight,
  onSelect
}) => {
  const { t } = useTranslation();
  const scrollbarsRef = useRef(null);
  const optionsRef = useRef(null);

  const getOptionsContainerClasses = (): string => {
    const classes = [styles.dropdownOptions__container];
    if (optionsPosition === 'right') {
      classes.push(styles['dropdownOptions__container--right']);
    }
    return classes.join(' ');
  };

  // Scroll to selected option
  const scrollToItem = (n: number) => {
    if (n < 0 || n >= options.length) return;

    const item = optionsRef.current.childNodes[n];
    if (!item) return;

    const itemOffset = item.offsetTop;
    const itemHeight = item.clientHeight;
    const topEdge = scrollbarsRef.current.getScrollTop();
    const listHeight = scrollbarsRef.current.getClientHeight();
    const bottomEdge = topEdge + listHeight;

    if (itemOffset < topEdge) {
      scrollbarsRef.current.scrollTop(itemOffset);
    } else if (itemOffset + itemHeight > bottomEdge) {
      const top = itemOffset + itemHeight - listHeight;
      scrollbarsRef.current.scrollTop(top > 0 ? top : 0);
    }
  };

  useEffect(() => {
    scrollToItem(highlighted);
  }, [highlighted]);

  useEffect(() => {
    const i = options.findIndex((item) => item.value === value);
    if (i !== -1) {
      scrollToItem(i);
    }
  }, []);

  return (
    <div className={getOptionsContainerClasses()} onMouseLeave={() => onHighlight(-1)}>
      {search !== '' && options.length === 0 && (
        <p className={styles.dropdownOptions__noResults}>{t('no_results')}</p>
      )}
      <Scrollbars ref={scrollbarsRef} autoHeight autoHeightMax={maxHeight}>
        <ul ref={optionsRef} className={styles.dropdownOptions__list}>
          {options.map((item, i) => (
            <Option
              key={i}
              item={item}
              highlight={highlighted === i}
              current={item.value === value}
              onClick={() => onSelect(item)}
              onMouseEnter={() => onHighlight(i)}
              size={size}
            />
          ))}
        </ul>
      </Scrollbars>
    </div>
  );
};

export default OptionsList;
